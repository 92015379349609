import './App.css';
import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import SubmitForm from './components/SubmitForm';
import QueryResults from './components/QueryResults';

const theme = createTheme({
  components: {
      MuiInputBase: {  // This targets all input components
      styleOverrides: {
        input: {
          color: 'white',  // Set text color to white          
        }
      }
    },
    MuiInputLabel: {  // This targets all input labels
      styleOverrides: {
        root: {
          color: 'white',  // Set label text color to white
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Target the border color directly on the root with higher specificity
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',  // Default border color
          },
          // Adjust hover state specifically
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray'  // Hover border color
          },
       
        }
      }
    }
  },
});

function App() {
  const [queryResult, setQueryResult] = useState('Awaiting Question...');

  const handleFormSubmit = (data) => {
    setQueryResult(data);
  };

  return (
    <ThemeProvider theme={theme}>
        <Stack className="App" spacing={5} >
          <header className="App-header">Building Code AI</header>
          <SubmitForm onFormSubmit={handleFormSubmit}/>
          <QueryResults queryResult={queryResult}/>
        </Stack>
    </ThemeProvider>
  );
}

export default App;
