import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem'; 
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';



function SubmitForm( {onFormSubmit} ) {
    const [queryText, setQueryText] = useState('Are AirBnbs allowed?');
    const [stateField, setStateField] = useState('VA');
    const [cityField, setCityField] = useState('Virginia Beach'); 

    
    const handleStateFieldChange = (e) => {
        setStateField(e.target.value);
        
        //Super simple validation for the POC
        if(e.target.value === 'VA') {
          setCityField('Virginia Beach');
         } else {
          setCityField('Oakland');
        }   

    };

    const handleCityFieldChange = (e) => {
        setCityField(e.target.value);
        //Super simple validation for the POC
        if(e.target.value === 'Virginia Beach') {
            setStateField('VA'); 
        } else {
            setStateField('CA');
        } 
    };
    
    const handleInputChange = (e) => {
        setQueryText(e.target.value);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
      // The URL of your API
        let apiUrl = '';
        const apiBase = process.env.NODE_ENV === 'development' ? 'http://localhost:8787/' : 'https://mn6gi5d7ahy6wmec2uz7vto5ae0yanco.lambda-url.us-east-2.on.aws/';
        const apiQuery = `?q=${encodeURIComponent(queryText)}&region=${stateField}`;
        apiUrl = apiBase + 'api/search/' + apiQuery;
        onFormSubmit("Loading...");

        fetch(apiUrl)
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            onFormSubmit(data.result);
        })
        .catch(error => {
            throw new Error('unknown error');
        });  
    };

    return (
        <form onSubmit={handleSubmit} >
            <FormControl>
                <Stack spacing={3} width="90vw">
                    <TextField
                        select
                        id="select-state"
                        value={stateField}
                        label="Select a State"
                        onChange={handleStateFieldChange}
                    >
                            <MenuItem value={'VA'}>Virginia</MenuItem>
                            <MenuItem value={'CA'}>California</MenuItem>
                    </TextField>
                    <TextField
                        select
                        id="select-city"
                        value={cityField}
                        label="Select a City"
                        onChange={handleCityFieldChange}
                    >
                            <MenuItem value={'Virginia Beach'}>Virginia Beach</MenuItem>
                            <MenuItem value={'Oakland'}>Oakland</MenuItem>
                    </TextField>
                    <TextField id="main-query" 
                            multiline
                            fullwidth="true"
                            label="Ask a Question" 
                            variant="outlined"
                            value={queryText}
                            onChange={handleInputChange}
                    />
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Send
                    </Button>
                </Stack>
            </FormControl>
        </form>
    );
} 

export default SubmitForm;