import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';



function QueryResults( {queryResult} ) {

    return (
        <Stack spacing={3} width="90vw">
            <Paper variant="default" elevation={4}>
                <TextField
                    id="query-results"
                    className="query-results"
                    value={queryResult}
                    fullWidth={true}
                    multiline 
                    sx={{color: 'red'}}          
                />
            </Paper>
        </Stack>
                                             
        
    );
} 

export default QueryResults;